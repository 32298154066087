.right {
  text-align: right;
}

.center {
  text-align: center;
}

.bold {
  font-weight: 600;
}

.header-line-right{
  height: 52px;
  width: 6%;
  background-color: #fc7c5b;
  position: absolute;
  margin-top: 28px;
}
.header-line{
  height: 2px;
  background-color: #fc7c5b;
  margin-top: 79px;
  position: absolute;
}
.ms-k{
  font-size: 18px;
  font-family: Arial, serif;
  position: absolute;
  margin-top: 37px;
  margin-right: 16px;
  color: #0464cc;
  font-weight: bold;
}
.ms-k-ar{
  font-size: 26px;
  font-family: Shamel, serif;
  position: absolute;
  margin-right: 15px;
  color: #0464cc;
  font-weight: bold;
}

.fs-20 {
  //font-family: sh, sans-serif;
  font-size: 19px;
  position: absolute;
}
.fs-12 {
  //font-family: sh, sans-serif;
  font-size: 14px;
  font-family: Arial, serif;
  position: absolute;
  margin-top: 8%;
}
.monospace{
  font-family: monospace;
}
.fs-13 {
  font-size: 10px;
}
.fs-14 {
  font-size: 12px;
}
.fs-15 {
  font-size: 15px;
}
.fs-8 {
  font-size: 8px;
}
.fs-30 {
  font-size: 30px;
}
.fs-22 {
  font-size: 20px;
}

.fs-45 {
  font-size: 45px;
}

.upper {
  text-transform: uppercase;
}
