@font-face {
  font-family: 'shamel';
  src: local('MyFont'), url(../fonts/Shamel-book.ttf) format('woff');
}

.app {
  margin: 60px auto 10px auto;
  width: 100%;
  height: 100%;
  font-family: Shamel, serif;
}
.div-invoice {
  margin: 20px auto 50px auto;
  width: 500px;
  height: 100%;
}

.invoice-btn {
  background-color: #0c9875;
  border: none;
  color: white;
  padding: 2px 5px 0 5px;
  cursor: pointer;
  font-family: Shamel, serif;
}
.btn-text{
  display: inline;
  margin-left: 5px;
}

/* Darker background on mouse-over */
.invoice-btn:hover {
  background-color: #035900;
}


.invoice-btn-print {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 150px; /* Need a specific value to work */
  display: inline;
  margin-top: -48px;
  background-color: DodgerBlue;
  border: none;
  color: white;
  padding: 2px 5px 0 5px;
  cursor: pointer;
  font-family: Shamel, serif;
}
.btn-loading {
  background-color: #3377b4;
}
.btn-text{
  display: inline;
  margin-left: 5px;
}

/* Darker background on mouse-over */
.invoice-btn-print:hover {
  background-color: RoyalBlue;
}


.input,
.span,
.select {
  display: inline-block;
  width: 98%;
  padding: 0;
  border: 1px dotted transparent;
  transition: background 0.1s ease-in-out, border-color 0.2s ease-in-out;
}

.input,
.select {
  &:hover,
  &:focus {
    background: $color-yellow;
  }

  &:focus {
    border-color: $color-cyan;
  }

  &.white {
    &:hover,
    &:focus {
      color: inherit;
    }
  }
}
.bottom-widget{
  position: absolute;
}
.aps-al-b-40{
  bottom: 207px;
}
.aps-al-b-20{
  bottom: 15px;
}
.aps-al-b-10{
  bottom: 8px;
}
.invoice-wrapper {
  direction: rtl;
  text-align: right;
  position: relative;
  background: #fff;
  box-shadow: 0 0 17px 0 rgba(16, 40, 73, 0.09);
  font-family: Shamel, serif;
  //min-height: 725px;
}


.image {
  position: relative;
  display: inline-block;
}

.image__file {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.image__upload {
  cursor: pointer;
  font-size: 12px;
  color: $placeholder-color;
  background-color: $color-gray;
  border: 2px dashed $placeholder-color;
  display: block;
  width: 100px;
  height: 20px;
  padding: 0;
  outline: none;
  transition: height 0.2s, font-size 0.2s;

  &:focus,
  &:hover {
    height: 100px;
    font-size: 14px;
  }
}

.image__img {
  display: block;
  max-width: 30%;
  margin-bottom: 2px;
}
.image_header {
  display: block;
  max-width: 100%;
  //margin-bottom: 2px;
}

.image__change {
  cursor: pointer;
  font-size: 14px;
  color: $placeholder-color;
  background-color: $color-gray;
  border: 2px dashed $placeholder-color;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s;

  &:hover,
  &:focus {
    opacity: 0.95;
  }
}

.image__edit,
.image__remove {
  background: #999 no-repeat center center;
  background-size: 10px 10px;
  border: 0;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -10px;
  text-indent: -9999em;
  opacity: 0;
  transition: opacity 0.2s, background-color 0.2s;

  &:hover,
  &:focus {
    background-color: #666;
  }

  .image:hover &,
  .image:focus-within & {
    opacity: 1;
  }
}
.image__width-wrapper {
  background-color: $color-white;
  padding: 10px 20px 25px;
  position: absolute;
  width: 270px;
  top: -70px;
  left: 0;
  border-radius: 4px;
  box-shadow: 2px 1px 6px rgba(0, 0, 0, 0.15);
}
