$background-color: #f2f3f5;
$placeholder-color: #aaa;

$color-dark: #222;
$color-dark-2: #ececec;
$color-gray: #e3e3e3;
$color-yellow: #fdf4db;
$color-cyan: #66afe9;
$color-white: #fff;
$color-red: #f03434;
$color-green: #26a65b;
$color-blue: #428bca;
