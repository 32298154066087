.mt-40 {
  margin-top: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-65{
  margin-right: 65px;
}
.mr--20 {
  margin-right: -20px;
}
.mr--15 {
  margin-right: -15px;
}
.mt-3 {
  margin-top: 3px;
}
.header-details {
  margin: 0;
}
.header-acount-number {
  margin-right: 10%;
}
.m-0 {
  margin: 0;
}
.p-0 {
  padding: 0;
}

.ml-30 {
  margin-left: 30px;
}

.p-4-8 {
  padding: 4px 8px;
}

.p-5 {
  padding: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}
